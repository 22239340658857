<template>
    <div id="header" class="container-fluid py-3 px-5 text-center text-md-start">
        <img class="logo" src="@/assets/logo.svg"/>
        <h2 class="mt-4 mb-3">{{ client }}</h2>
        <img class="circle d-none d-md-block" src="@/assets/circle.svg"/>
    </div>
    <div class="container-fluid p-2 overflow-hidden">
        <div class="row g-4 pt-3 pt-lg-0">
            <div v-for="item in projects" :key="item.id" class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-2 mt-lg-4">
              <div v-if="item.featured_image && (item.featured_image.type === 'image/jpeg' || item.featured_image.type === 'image/gif' || item.featured_image.type === 'image/png')" class="content ratio ratio-1x1">
                  <div class="content-overlay"></div>
                  <img loading="lazy" class="content-image" :src="'https://u38ouu9f.directus.app/assets/' + item.featured_image.id" :alt=item.name>
                  <div class="content-details">
                       <a :href="baseUrl + '/project/' + item.id" class="content-button">View more</a>
                  </div>
              </div>

            <div v-if="item.featured_image && item.featured_image.type === 'video/mp4'" class="content ratio ratio-1x1">
                <video autoplay muted loop>
                    <source :src="'https://u38ouu9f.directus.app/assets/' + item.featured_image.id" type="video/mp4" />
                </video>
                    <div class="content-overlay"></div>
                    <div class="content-details">
                       <a :href="baseUrl + '/project/' + item.id" class="content-button">View more</a>
                    </div>
                </div>
            </div>
        </div>
        </div>
</template>

<script>
import axios from 'axios'

export default {
    name: "ClientPage",
    props: ["id"],
    data() {
        return {
        baseUrl: '',
        client: '',
        projects: [],
        }
    },
    async mounted() {
        this.baseUrl = window.location.origin
        const key = this.id
        var get_projects = `https://u38ouu9f.directus.app/items/project?filter[client][_eq]=`+ key +`&fields=*,featured_image.*,*,client.*`
        try {
            const { data: response } = await axios.get(get_projects)
            this.projects = response.data
            this.client = response.data[0].client.name
            document.title = this.client;
        } catch (error) {
            console.log(error)
        }
    }
}
</script>
<style>
h1 {
     font-family: 'Montserrat', sans-serif;
}
 .content {
     position: relative;
     margin: auto;
     overflow: hidden;
     width: 100%;
     height: 100%;
}
 .content video {
     width: 100%;
     height: 100%;
     position: absolute;
     object-fit: cover;
     z-index: 0;
}
 .content .content-overlay {
     background: rgba(0,0,0,0.5);
     position: absolute;
     height: 100%;
     width: 100%;
     left: 0;
     top: 0;
     bottom: 0;
     right: 0;
     opacity: 0;
     -webkit-transition: all 0.4s ease-in-out 0s;
     -moz-transition: all 0.4s ease-in-out 0s;
     transition: all 0.4s ease-in-out 0s;
     z-index: 1;
}
 .content:hover .content-overlay{
     opacity: 1;
}
 .content-image{
     width: 100%;
     height: 100%;
     object-fit: cover;
     object-position: center;
}
 .content-details {
     position: absolute;
     display: flex;
     justify-content: center;
     align-items: center;
     top: 0%;
     left: 0%;
     opacity: 0;
     -webkit-transition: all 0.3s ease-in-out 0s;
     -moz-transition: all 0.3s ease-in-out 0s;
     transition: all 0.3s ease-in-out 0s;
     z-index: 2;
}
 .content:hover .content-details{
     opacity: 1;
}
 .content-details a.content-button {
     font-family: 'Montserrat', sans-serif;
     background-image: linear-gradient(to top, #000 50%, #fff 50%), linear-gradient(to top, #fff 50%, transparent 50%);
     -webkit-background-clip: text,padding-box;
     background-clip: text,padding-box;
     -webkit-text-fill-color: transparent;
     background-origin: padding-box;
     background-size: 100% 200%;
     color: #fff;
     background-position: top;
     font-weight: 500;
     letter-spacing: 0.05em;
     line-height: 1;
     text-transform: uppercase;
     text-decoration: none;
     padding: 5px 20px;
     border: 2px solid white;
     border-radius: 30px;
     -webkit-transition: all 0.3s ease;
     -moz-transition: all 0.3s ease;
     -ms-transition: all 0.3s ease;
     -o-transition: all 0.3s ease;
     transition: all 0.3s ease;
     cursor: pointer;
}
 .content-details a.content-button:hover {
     background-position: bottom;
     border: 2px solid white;
}
</style>
