<template>
    <div id="header" class="container-fluid py-3 px-5 text-center text-md-start mb-5">
        <img class="logo" src="@/assets/logo.svg"/>
        <h2 class="mt-4 mb-3">Clients</h2>
        <img class="circle d-none d-md-block" src="@/assets/circle.svg"/>
    </div>
    <div class="container-fluid px-5">
        <div class="row">
            <div class="col-12">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Name</th>
                      <th scope="col">Projects</th>
                    </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(item, index) in clients" :key="item.id">
                      <th scope="row">{{ index+1 }}</th>
                      <td><h4>{{ item.name }}</h4></td>
                      <td><a :href="baseUrl + '/client/' + item.id" class="btn btn-primary">View</a></td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
        </div>
</template>

<script>
import axios from 'axios'

export default {
    name: "BlogPage",
    data() {
        return {
        baseUrl: '',
        clients: [],
        }
    },
    async mounted() {
        this.baseUrl = window.location.origin
        document.title = "Clients";
        var url = 'https://u38ouu9f.directus.app/items/client'
        try {
            const { data: response } = await axios.get(url) //use data destructuring to get data from the promise object
            this.clients = response.data
            return response
        } catch (error) {
            console.log(error)
        }
    }
}
</script>
