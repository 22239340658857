import { createWebHistory, createRouter } from "vue-router";
import Home from "../components/Home";
import Client from "../components/Client";
import Project from "../components/Project";
import Notfound from "../components/NotFound";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: '/client/:id',
    name: 'Client',
    component: Client,
    props: true,
  },
  {
    path: '/project/:id',
    name: 'Project',
    component: Project,
    props: true,
  },
  {
    path: "/:catchAll(.*)",
    name: "Notfound",
    component: Notfound
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

const DEFAULT_TITLE = 'Clients';
router.beforeEach((to) => {
  document.title = to.meta.title || DEFAULT_TITLE;
});


export default router;
