<template>
  <router-view />
</template>

<style>
@font-face {
  font-family: Qanelas;
  src: local("Qanelas"),
  url(./fonts/Qanelas-Regular.ttf) format("truetype");
}

@font-face {
  font-family: Qanelas;
  src: local("Qanelas"),
  url(./fonts/Qanelas-SemiBold.ttf) format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: Qanelas;
  src: local("Qanelas"),
  url(./fonts/Qanelas-Bold.ttf) format("truetype");
  font-weight: bold;
}

h1, h2, h3, h4, h5, h6 {
    font-family: "Qanelas", Helvetica, Arial;
}
#header {
    position: relative;
    background-color: #EDC8AB !important;
}
#header img.logo {
width: 250px;
}

#header img.circle {
width: 200px;
right: 0;
bottom: 0;
position: absolute;
}
#header h2 {
color: #814D68;
font-size: 2.5rem;
}

table td, table th {
    vertical-align: middle;
}
table>:not(caption)>*>*, .table>thead>th {
border-color: #712B4D;
}
.table {
color: #712b62 !important;
}
table h4 {
margin: 0;
color: #712B4D;
}
.btn-primary {
    outline: none !important;
    box-shadow: none !important;
    color: #fff;
    background-color: #1E2554 !important;
    border-color: #1E2554 !important;
}
.btn-primary:hover {
    color: #fff;
    background-color: #171c40;
    border-color: #171c40;
}
</style>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'App',
  setup() {

  },
})
</script>
