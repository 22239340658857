<template>
  <div class="d-flex align-items-center justify-content-center vh-100 bg-dark">
        <h1 class="display-1 fw-bold text-white">404</h1>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Notfound',
  mounted() {
    document.title = "404";
  },
})
</script>
