<template>
    <div id="header" class="container-fluid py-3 px-5 text-center text-md-start">
        <img class="logo" src="@/assets/logo.svg"/>
        <h2 class="mt-4 mb-3">{{ client }} - {{ project }}</h2>
        <img class="circle d-none d-md-block" src="@/assets/circle.svg"/>
    </div>
    <div class="container-fluid p-2 overflow-hidden">
        <lightgallery
        :settings="{ speed: 500, plugins: plugins, download: false, selector: 'a' }"
        :onInit="onInit"
        className="row"
        data-masonry="{'percentPosition': true }"
        :onBeforeSlide="onBeforeSlide">
            <masonry-wall :items="filterAssets(assets)" :ssr-columns="1" :column-width="400" :gap="16">
                <template #default="{ item, index }">
                    <template v-if="item.asset_id !== null && item.asset_id && item.asset_id.file && item.asset_id.type === 'video'">
                        <a
                        class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 overflow-hidden d-flex justify-content-center align-items-center"
                        data-lg-size="1280-720"
                        :data-video=getSource(index)
                        >
                        <video v-if="item.asset_id !== null && item.asset_id &&  item.asset_id.file && item.asset_id.type === 'video'" class="w-100" :title=item.asset_id.title autoplay muted loop>
                            <source :src="'https://u38ouu9f.directus.app/assets/' + item.asset_id.file.id" type="video/mp4" />
                        </video>
                        </a>
                    </template>
                    <template v-else-if="item.asset_id !== null && item.asset_id &&  item.asset_id.file && item.asset_id.type === 'pdf'">
                        <button type="button" class="pdf-thumbnail p-0 border-0 col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 overflow-hidden d-flex justify-content-center align-items-center" data-bs-toggle="modal" :data-bs-target="'#pdfModal' + index">
                        <Preview :fileUrl="'https://u38ouu9f.directus.app/assets/' + item.asset_id.file.id" :uid="'pdf' + index"></Preview>
                        </button>
                    </template>
                    <template v-else-if="item.asset_id !== null">
                        <a :src="'https://u38ouu9f.directus.app/assets/' + item.asset_id.file.id" class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-3 overflow-hidden d-flex justify-content-center align-items-center">
                        <img v-if="item.asset_id.file && (item.asset_id.type === 'image' || item.asset_id.type === 'gif')" loading="lazy" className="img-responsive content-image w-100" :src="'https://u38ouu9f.directus.app/assets/' + item.asset_id.file.id + '?fit=cover&width=600&height=600&quality=100'" :alt=item.asset_id.title />
                        </a>
                    </template>
                </template>
            </masonry-wall>
        </lightgallery>

              <div class="row">
            <div class="col-12 pb-4 pt-5 px-5 text-center"><a :href="baseUrl + '/client/' + client_id" class="btn btn-primary">Return to Homepage</a></div>
            </div>
    </div>


    <template v-for="(item, index) in assets" :key="item.id">
        <template v-if="item.asset_id !== null && item.asset_id.file && item.asset_id.type === 'pdf'">
            <div class="modal fade" :id="'pdfModal' + index" tabindex="-1" aria-labelledby="pdfModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title text-primary" id="pdfModalLabel">{{ item.asset_id.title }}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                <iframe
                :src="'https://u38ouu9f.directus.app/assets/' + item.asset_id.file.id"
                frameBorder="0"
                scrolling="auto"
                height="100%"
                width="100%"
            ></iframe>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Close</button>
                </div>
                </div>
            </div>
            </div>
        </template>
    </template>
</template>
<script>
import axios from 'axios'
import Lightgallery from 'lightgallery/vue'
import lgZoom from 'lightgallery/plugins/zoom'
import lgVideo from "lightgallery/plugins/video";
import Preview from "./Preview"
import MasonryWall from '@yeger/vue-masonry-wall'

let lightGallery = null;

export default {
    name: "ClientPage",
    props: ["id"],
    components: {
        Lightgallery,
        Preview,
        MasonryWall,
    },
    watch: {
        items() {
            this.$nextTick(() => {
                lightGallery.refresh();
            });
        },
    },
    data() {
        return {
         items: [
        { title: 'First', description: 'The first item.' },
        { title: 'Second', description: 'The second item.' },
        ],
        plugins: [lgZoom, lgVideo],
        baseUrl: '',
        client: '',
        client_id: '',
        project: '',
        assets: [],
        height: 0,
        width: 0
        }
    },
    created()  {
        window.addEventListener("resize", this.resizeHandler);
    },
    unmounted()  {
        window.removeEventListener("resize", this.resizeHandler);
    },
    async mounted() {
        this.height =  window.innerHeight;
        this.width =  window.innerWidth;
        this.baseUrl = window.location.origin
        const key = this.id
        var get_assets = `https://u38ouu9f.directus.app/items/asset_project?filter[project_id][_eq]=`+ key + `&fields=*.*.*`
        try {
            const { data: response } = await axios.get(get_assets)
            this.assets = response.data
            this.project = response.data[0].project_id.name
            this.client = response.data[0].project_id.client.name
            this.client_id = response.data[0].project_id.client.id
            document.title = this.client + ' - ' + this.project;

        } catch (error) {
            console.log(error)
        }
        this.$nextTick(function () {
             this.updateMasonry();
             if(this.assets.length < 1) {
                this.getClient();
             }
        })
    },
    ready: function(){
         this.updateMasonry();
    },
    methods: {
       async getClient() {
            const key = this.id
            var get_client = `https://u38ouu9f.directus.app/items/project/`+ key + `?fields=*,client.*`
            try {
                const { data: client_response } = await axios.get(get_client)
                console.log(client_response);
                this.client = client_response.data.client.name
                this.client_id = client_response.data.client.id
            } catch (error) {
                console.log(error)
            }
       },
       filterAssets(assets) {
            return assets.filter(item => {
                return item.asset_id !== null;
            })
        },
        resizeHandler()  {
            this.height =  window.innerHeight;
            this.width =  window.innerWidth;
            console.log('resized');
            setTimeout(() => lightGallery.refresh(), 50);
        },
        onInit: (detail) => {
            lightGallery = detail.instance;
        },
        updateSlides: function () {
            lightGallery.refresh();
        },
        updateMasonry: function(){
           setTimeout(() => lightGallery.refresh(), 300);
        },
        getSource(index) {
            var file = this.assets[index].asset_id.file.id;
            var liveData= {
                source: [
                    {
                    src:  'https://u38ouu9f.directus.app/assets/' + file,
                    type: "video/mp4"
                    }
                ],
                tracks: [],
                attributes: {
                    preload: false,
                    playsinline: true,
                    controls: true
                }
                };

            return JSON.stringify(liveData);
        }
    },
}
</script>
<style lang="css" scoped>
    @import 'lightgallery/css/lightgallery.css';
    @import 'lightgallery/css/lg-thumbnail.css';
    @import 'lightgallery/css/lg-zoom.css';
    @import 'lightgallery/css/lg-video.css';
</style>
<style>
h1 {
     font-family: 'Montserrat', sans-serif;
}
.masonry-item button, .masonry-item a {
    width: 100%;
}
canvas {
    width: 100%;
    height: 100%;
}
.modal-content
{
  height: 800px
}
.modal-header, .modal-footer, .modal-body {
    background-color: #EDC8AB;
}
.modal-header {
padding: 15px 10px !important;
border-bottom: 0 !important;
}
.modal-body {
padding: 0 10px !important;
}
.modal-footer {
padding: 5px 10px !important;
border-top: 0 !important;
}
.modal-header .text-primary {
   color: #1E2554 !important;
}
 .content {
     position: relative;
     margin: auto;
     overflow: hidden;
     width: 100%;
     height: 100%;
}
 .content video {
     width: 100%;
     height: 100%;
     position: absolute;
     object-fit: cover;
     z-index: 0;
}
 .content .content-overlay {
     background: rgba(0,0,0,0.5);
     position: absolute;
     height: 100%;
     width: 100%;
     left: 0;
     top: 0;
     bottom: 0;
     right: 0;
     opacity: 0;
     -webkit-transition: all 0.4s ease-in-out 0s;
     -moz-transition: all 0.4s ease-in-out 0s;
     transition: all 0.4s ease-in-out 0s;
     z-index: 1;
}
 .content:hover .content-overlay{
     opacity: 1;
}
 .content-image {
     width: 100%;
     height: 100%;
     object-fit: cover;
     object-position: center;
}
 .content-details {
     position: absolute;
     display: flex;
     justify-content: center;
     align-items: center;
     top: 0%;
     left: 0%;
     opacity: 0;
     -webkit-transition: all 0.3s ease-in-out 0s;
     -moz-transition: all 0.3s ease-in-out 0s;
     transition: all 0.3s ease-in-out 0s;
     z-index: 2;
}
 .content:hover .content-details{
     opacity: 1;
}
 .content-details a.content-button {
     font-family: 'Montserrat', sans-serif;
     background-image: linear-gradient(to top, #000 50%, #fff 50%), linear-gradient(to top, #fff 50%, transparent 50%);
     -webkit-background-clip: text,padding-box;
     background-clip: text,padding-box;
     -webkit-text-fill-color: transparent;
     background-origin: padding-box;
     background-size: 100% 200%;
     color: #fff;
     background-position: top;
     font-weight: 500;
     letter-spacing: 0.05em;
     line-height: 1;
     text-transform: uppercase;
     text-decoration: none;
     padding: 5px 20px;
     border: 2px solid white;
     border-radius: 30px;
     -webkit-transition: all 0.3s ease;
     -moz-transition: all 0.3s ease;
     -ms-transition: all 0.3s ease;
     -o-transition: all 0.3s ease;
     transition: all 0.3s ease;
     cursor: pointer;
}
 .content-details a.content-button:hover {
     background-position: bottom;
     border: 2px solid white;
}
 .fadeIn-bottom{
     top: 80%;
}
.pdf-thumbnail img {
    width: 100%;
}
</style>
