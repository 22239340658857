<template>
  <canvas :id="uid" />
</template>

<script>
import * as pdfjsLib from "pdfjs-dist/build/pdf";

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.js`

export default {
 name: 'Preview',
 props: {
    fileUrl: {
      type: String,
    },
    uid: {
      type: String,
    },
  },
  data() {
        return {
        width: '600',
        height: '600',
        }
    },
  mounted() {
    this.renderThumbnails()
  },
  methods: {
    async renderThumbnails() {
      const worker = new pdfjsLib.PDFWorker()
      let pdf = await pdfjsLib
        .getDocument({ url: this.fileUrl, worker: worker })
        .promise.then((pdf) => pdf)
      const page = await pdf.getPage(1)
      let viewport = page.getViewport({ scale: 1.0 })
      viewport = page.getViewport({ scale: this.width / viewport.width })
      let canvas = document.getElementById(this.uid)
      canvas.height = viewport.height
      canvas.width = viewport.width
      const context = canvas.getContext('2d')
      await page.render({ canvasContext: context, viewport: viewport })
    },
  },
}
</script>

<style>
</style>
